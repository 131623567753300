<template>
  <div class="imelink-noticeview" :style="{ height: `${height}px` }" ref="wrap">
    <respon-notice
      v-if="control.noticeViewState"
      @refresh="control._changeMessageView(control.currentContact)"
      :type="control.noticeViewState"
    />
    <template v-else>
      <template v-if="control.currentContact.isNotice == 'friend_invite'">
        <div
          :class="[
            'imelink-notice',
            { 'imelink-notice--active': notice.status == 'none' },
          ]"
          v-for="(notice, index) in notices"
          :key="index"
        >
          <div class="imelink-notice__inner">
            <avatar
              @click.native="_handleAvatarClick(notice.fromUser.id)"
              class="imelink-notice__avatar"
              :src="notice.fromUser.avatar || ''"
              size="40px"
            />
            <div class>
              <p class="imelink-notice__name">
                {{ notice.fromUser.displayName }}
              </p>
              <p class="imelink-notice__remark">
                <span class="imelink-notice__time">
                  {{ notice.sendTime | parseTime }}
                </span>
                <span v-html="notice.text"></span>
              </p>
            </div>
          </div>
          <div class="imelink-notice__action">
            <template v-if="notice.status == 'none'">
              <el-button
                icon="el-icon-check"
                type="primary"
                circle
                :loading="notice._confirmLoading"
                size="small"
                @click="handle(notice, 'agree')"
              ></el-button>
              <el-button
                icon="el-icon-close"
                circle
                :loading="notice._cancelLoading"
                size="small"
                @click="handle(notice, 'reject')"
              ></el-button>
            </template>
            <template v-else-if="notice.status == 'agree'">
              <el-button
                style="pointer-events: none;"
                icon="el-icon-check"
                circle
                plain
                type="primary"
                size="small"
              ></el-button>
            </template>
            <template v-else-if="notice.status == 'reject'">
              <el-button
                style="pointer-events: none;"
                icon="el-icon-close"
                circle
                plain
                type="info"
                size="small"
              ></el-button>
            </template>
          </div>
        </div>
      </template>
      <template
        v-if="
          control.currentContact.isNotice == 'system_message' ||
            control.currentContact.isNotice == 'wallet_message'
        "
      >
        <div
          :class="['imelink-notice']"
          v-for="(notice, index) in notices"
          :key="index"
          @click.stop="openNotice(notice)"
        >
          <div class="imelink-notice__inner">
            <div class="imelink-notice__remark">
              <p class="imelink-notice__time">
                {{ notice.sendTime | parseTime }}
              </p>
              <div class="imelink-notice__remarkBox">
                <span class="unread-notice" v-if="notice.status == 2"></span>
                <p
                  class="imelink-notice__remarkname"
                  v-html="
                    $emoReplaceToImage(
                      $options.filters.convertUnicode(notice.text)
                    )
                  "
                ></p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="control.currentContact.isNotice == 'group_message'">
        <div
          :class="[
            'imelink-notice',
            { 'imelink-notice--active': notice.status == 'none' },
          ]"
          v-for="(notice, index) in notices"
          :key="index"
        >
          <div class="imelink-notice__inner">
            <avatar
              @click.native="_handleAvatarClick(notice.id)"
              class="imelink-notice__avatar"
              :src="notice.avatar || ''"
              size="40px"
            />
            <div class>
              <p class="imelink-notice__name">{{ notice.displayName }}</p>
              <p class="imelink-notice__remark">
                <span class="imelink-notice__time">
                  {{ notice.sendTime | parseTime }}
                </span>
                <span
                  v-if="notice.tipContent"
                  v-html="notice.tipContent"
                ></span>
              </p>
            </div>
          </div>
          <div class="imelink-notice__action">
            <template v-if="notice.status == 'none'">
              <el-button
                icon="el-icon-check"
                type="primary"
                circle
                :loading="notice._confirmLoading"
                size="small"
                @click="handle(notice, 'agree')"
              ></el-button>
              <el-button
                icon="el-icon-close"
                circle
                :loading="notice._cancelLoading"
                size="small"
                @click="handle(notice, 'reject')"
              ></el-button>
            </template>
            <template v-else-if="notice.status == 'agree'">
              <el-button
                style="pointer-events: none;"
                icon="el-icon-check"
                circle
                plain
                type="primary"
                size="small"
              ></el-button>
            </template>
            <template v-else-if="notice.status == 'reject'">
              <el-button
                style="pointer-events: none;"
                icon="el-icon-close"
                circle
                plain
                type="info"
                size="small"
              ></el-button>
            </template>
          </div>
        </div>
      </template>
    </template>
    <div
      :class="[
        'imelink-noticeview__page',
        initPage == true && notices.length < 10
          ? 'imelink-noticeview__page--disabled'
          : '',
      ]"
    >
      <i class="el-icon-d-arrow-left" @click="_emitRequestNotice('first')"></i>
      <i class="el-icon-arrow-left" @click="_emitRequestNotice('prev')"></i>
      <i class="el-icon-arrow-right" @click="_emitRequestNotice('next')"></i>
      <i class="el-icon-d-arrow-right" @click="_emitRequestNotice('last')"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeView",
  inject: ["control"],
  data() {
    return {
      notices: [],
      initPage: true,
      height: 0,
    };
  },
  watch: {
    "$store.state.imStore.IMWindowSize"() {
      this._resetScreenSize();
    },
    "control.noticeAll"(newly) {
      this.notices = newly.map((item) => {
        item._confirmLoading = false;
        item._cancelLoading = false;
        return item;
      });
    },
  },
  mounted() {
    this.control.$on("request-notice", (a, b, c) => {
      if (c == "init") {
        this.initPage = true;
      }
    });
    this._resetScreenSize();
  },
  created() {},
  methods: {
    openNotice(notice) {
      if (notice.tipType == 45) {
        this.$store.commit("setNotificationDialogId", notice.typeId);
      }
    },
    async _resetScreenSize() {
      await this.$nextTick();
      try {
        setTimeout(() => {
          this.height = this.$refs.wrap.parentNode.clientHeight;
        }, 200);
      } catch (error) {}
    },
    _emitRequestNotice(type) {
      this.initPage = false;
      this.control.$emit(
        "request-notice",
        this.control.currentContact,
        {
          success: (data = []) => {
            this.control.noticeAll = data;
          },
          error: () => {
            console.log("error from imelink/notice-view.vue:240:30:");
          },
        },
        type
      );
    },
    _handleNextPage() {
      this._emitRequestNotice("next");
    },
    _handlePrevPage() {
      this._emitRequestNotice("prev");
    },
    _handleAvatarClick(id) {
      this.jumpPage({
        name: "personalCenter",
        params: { id: id },
      });
    },
    _changeStatus(contact, type, status) {
      if (type == "agree") {
        contact._confirmLoading = status;
      }
      if (type == "reject") {
        contact._cancelLoading = status;
      }
      if (type == "none") {
        contact._confirmLoading = status;
        contact._cancelLoading = status;
      }
      if (status == false) {
        contact.status = type;
      }
    },
    handle(contact, type) {
      this._changeStatus(contact, type, true);
      this.$forceUpdate();

      if (this.control.currentContact.isNotice == "group_message") {
        this.control.$emit("group-apply", contact, {
          type,
          success: () => {
            this._changeStatus(contact, type, false);
          },
          error: () => {
            this._changeStatus(contact, "none", false);
            this.$forceUpdate();
          },
        });
      } else {
        this.control.$emit("friend-invite", contact, {
          type,
          success: () => {
            this.control.messageAll[contact.fromUser.id] = null;
            this._changeStatus(contact, type, false);
          },
          error: () => {
            this._changeStatus(contact, "none", false);
            this.$forceUpdate();
          },
        });
      }
    },
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

+b(imelink-noticeview) {
  background: imelink-message-view-background;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  scrollbar-light();

  +e(page--disabled) {
    display: none;
  }

  +e(page) {
    text-align: right;
    font-size: 16px;
    padding: 10px;

    i {
      cursor: pointer;
      border-radius: 4px;
      padding: 6px;
      color: #666;

      &:hover {
        background: #eee;
        color: #000;
      }
    }
  }
}

+b(imelink-notice) {
  padding: 15px 15px;
  padding-top: 20px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  cursor pointer;
  a {
    color: #107cf6;
  }

  p {
    margin: 0;
  }

  &:hover:not(.imelink-notice--active) {
    background: #f9f9f9;
  }

  +m(active) {
    background: #f3fbf5;

    .imelink-notice__action {
      opacity: 1;
      filter: grayscale(0);
    }
  }

  +e(inner) {
    display: flex;
  }

  +e(avatar) {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  +e(time) {
    color: #666;
    padding-right: 5px;
  }

  +e('name') {
    font-size: 14px;
    padding-bottom: 2px;
  }

  +e(remark) {
    font-size: 12px;
    color: #999;
  }

  +e(action) {
    transition: all ease-in-out 0.3s;
    position-center-y();
    right: 20px;

    i {
      font-size: 14px;
    }
  }
}

.imelink-notice__remarkBox{
  overflow: hidden;
  line-height: 24px;
  .unread-notice {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #F57575
    margin-right: 8px;
  }
}

.imelink-notice__remarkname {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  display inline-flex;
  align-items center;
  justify-items center;
  i {
    padding-left: 2px;
  }
}
</style>
