var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrap",
      staticClass: "imelink-noticeview",
      style: { height: `${_vm.height}px` }
    },
    [
      _vm.control.noticeViewState
        ? _c("respon-notice", {
            attrs: { type: _vm.control.noticeViewState },
            on: {
              refresh: function($event) {
                return _vm.control._changeMessageView(
                  _vm.control.currentContact
                )
              }
            }
          })
        : [
            _vm.control.currentContact.isNotice == "friend_invite"
              ? _vm._l(_vm.notices, function(notice, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "imelink-notice",
                        { "imelink-notice--active": notice.status == "none" }
                      ]
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "imelink-notice__inner" },
                        [
                          _c("avatar", {
                            staticClass: "imelink-notice__avatar",
                            attrs: {
                              src: notice.fromUser.avatar || "",
                              size: "40px"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm._handleAvatarClick(
                                  notice.fromUser.id
                                )
                              }
                            }
                          }),
                          _c("div", {}, [
                            _c("p", { staticClass: "imelink-notice__name" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(notice.fromUser.displayName) +
                                  "\n            "
                              )
                            ]),
                            _c("p", { staticClass: "imelink-notice__remark" }, [
                              _c(
                                "span",
                                { staticClass: "imelink-notice__time" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("parseTime")(notice.sendTime)
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c("span", {
                                domProps: { innerHTML: _vm._s(notice.text) }
                              })
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "imelink-notice__action" },
                        [
                          notice.status == "none"
                            ? [
                                _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-check",
                                    type: "primary",
                                    circle: "",
                                    loading: notice._confirmLoading,
                                    size: "small"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handle(notice, "agree")
                                    }
                                  }
                                }),
                                _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-close",
                                    circle: "",
                                    loading: notice._cancelLoading,
                                    size: "small"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handle(notice, "reject")
                                    }
                                  }
                                })
                              ]
                            : notice.status == "agree"
                            ? [
                                _c("el-button", {
                                  staticStyle: { "pointer-events": "none" },
                                  attrs: {
                                    icon: "el-icon-check",
                                    circle: "",
                                    plain: "",
                                    type: "primary",
                                    size: "small"
                                  }
                                })
                              ]
                            : notice.status == "reject"
                            ? [
                                _c("el-button", {
                                  staticStyle: { "pointer-events": "none" },
                                  attrs: {
                                    icon: "el-icon-close",
                                    circle: "",
                                    plain: "",
                                    type: "info",
                                    size: "small"
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                })
              : _vm._e(),
            _vm.control.currentContact.isNotice == "system_message" ||
            _vm.control.currentContact.isNotice == "wallet_message"
              ? _vm._l(_vm.notices, function(notice, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: ["imelink-notice"],
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openNotice(notice)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "imelink-notice__inner" }, [
                        _c("div", { staticClass: "imelink-notice__remark" }, [
                          _c("p", { staticClass: "imelink-notice__time" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm._f("parseTime")(notice.sendTime)) +
                                "\n            "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "imelink-notice__remarkBox" },
                            [
                              notice.status == 2
                                ? _c("span", { staticClass: "unread-notice" })
                                : _vm._e(),
                              _c("p", {
                                staticClass: "imelink-notice__remarkname",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$emoReplaceToImage(
                                      _vm.$options.filters.convertUnicode(
                                        notice.text
                                      )
                                    )
                                  )
                                }
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                })
              : _vm._e(),
            _vm.control.currentContact.isNotice == "group_message"
              ? _vm._l(_vm.notices, function(notice, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "imelink-notice",
                        { "imelink-notice--active": notice.status == "none" }
                      ]
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "imelink-notice__inner" },
                        [
                          _c("avatar", {
                            staticClass: "imelink-notice__avatar",
                            attrs: { src: notice.avatar || "", size: "40px" },
                            nativeOn: {
                              click: function($event) {
                                return _vm._handleAvatarClick(notice.id)
                              }
                            }
                          }),
                          _c("div", {}, [
                            _c("p", { staticClass: "imelink-notice__name" }, [
                              _vm._v(_vm._s(notice.displayName))
                            ]),
                            _c("p", { staticClass: "imelink-notice__remark" }, [
                              _c(
                                "span",
                                { staticClass: "imelink-notice__time" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("parseTime")(notice.sendTime)
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              notice.tipContent
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(notice.tipContent)
                                    }
                                  })
                                : _vm._e()
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "imelink-notice__action" },
                        [
                          notice.status == "none"
                            ? [
                                _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-check",
                                    type: "primary",
                                    circle: "",
                                    loading: notice._confirmLoading,
                                    size: "small"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handle(notice, "agree")
                                    }
                                  }
                                }),
                                _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-close",
                                    circle: "",
                                    loading: notice._cancelLoading,
                                    size: "small"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handle(notice, "reject")
                                    }
                                  }
                                })
                              ]
                            : notice.status == "agree"
                            ? [
                                _c("el-button", {
                                  staticStyle: { "pointer-events": "none" },
                                  attrs: {
                                    icon: "el-icon-check",
                                    circle: "",
                                    plain: "",
                                    type: "primary",
                                    size: "small"
                                  }
                                })
                              ]
                            : notice.status == "reject"
                            ? [
                                _c("el-button", {
                                  staticStyle: { "pointer-events": "none" },
                                  attrs: {
                                    icon: "el-icon-close",
                                    circle: "",
                                    plain: "",
                                    type: "info",
                                    size: "small"
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                })
              : _vm._e()
          ],
      _c(
        "div",
        {
          class: [
            "imelink-noticeview__page",
            _vm.initPage == true && _vm.notices.length < 10
              ? "imelink-noticeview__page--disabled"
              : ""
          ]
        },
        [
          _c("i", {
            staticClass: "el-icon-d-arrow-left",
            on: {
              click: function($event) {
                return _vm._emitRequestNotice("first")
              }
            }
          }),
          _c("i", {
            staticClass: "el-icon-arrow-left",
            on: {
              click: function($event) {
                return _vm._emitRequestNotice("prev")
              }
            }
          }),
          _c("i", {
            staticClass: "el-icon-arrow-right",
            on: {
              click: function($event) {
                return _vm._emitRequestNotice("next")
              }
            }
          }),
          _c("i", {
            staticClass: "el-icon-d-arrow-right",
            on: {
              click: function($event) {
                return _vm._emitRequestNotice("last")
              }
            }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }